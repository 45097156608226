// import dependencies
import React from 'react';
import { graphql } from 'gatsby';

// import components
import Layout from '../components/layout';
import Post from "../components/post";
import PostNavButtons from '../components/posts-nav-buttons';
import Section from '../components/section';

// import styles and assets
import * as styles from './styles/blog-page.module.scss';

export default function Tag({ pageContext, data }) {
	const { tag } = pageContext;
	const { edges, totalCount } = data.allMarkdownRemark;
	const tagHeader = `${totalCount} entrada${totalCount === 1 ? '' : 's'} de "${tag}"`;

	return (
		<Layout>
			<Section>
				<PostNavButtons/>
				<div className={styles.blogContainer}>
					<div className={styles.blogPageHeader}>
						<h1>{tagHeader}</h1>
					</div>
					{edges.map(({ node }) => {
						return (
							<Post index={node.id} node={node}/>
						);
					})}
				</div>
			</Section>
		</Layout>
	);
}

export const pageQuery = graphql`
  	query($tag: String) {
	  allMarkdownRemark(
		sort: {fields: frontmatter___date, order: DESC}
		filter: { frontmatter: { tags: { in: [$tag] } } }
	  ) {
		totalCount
		edges {
		  node {
			id
			excerpt(pruneLength: 200, truncate: true, format: HTML)
			frontmatter {
			  title
			  date
			  path
			  tags
			  featuredImage {
				  name
				  childImageSharp {
					gatsbyImageData(width: 600, height: 380, formats: [WEBP])
				  }
			  }
			}
		  }
		}
	  }
	}
`;